import get from 'lodash/get';
import { MAIN_WEB_URL, SECOND_WEB_URL } from '../apis/config';
import { Product } from '../types/productTypes';
import { generateProductLink, getProductImage } from './helper';

export const ProductMetaGenerator = (data: Product, productVariant) => {
	const text = generateProductTitle(data);
	let extra = 'Arogga Online Pharmacy';
	let d_extra = 'Arogga online pharmacy';
	if (data.p_type === 'medicine') {
		extra = 'Arogga Online Pharmacy';
	} else if (data.p_type === 'beauty') {
		extra = 'Arogga Beauty Store';
		d_extra = 'Arogga beauty store';
	} else if (data.p_type === 'food') {
		extra = 'Arogga Ltd';
		d_extra = 'Arogga';
	} else if (data.p_type === 'pet_care') {
		extra = 'Arogga Ltd';
		d_extra = 'Arogga';
	}
	const bn = get(data, 'translation.bn.p_name', '');

	// const title = `${text} - ${extra}`;
	const title = `${text} ${bn ? `- ${bn}` : ''} - ${extra}`;
	const description =
		data?.p_meta_description ||
		`Order ${text} by ${data.p_manufacturer || get(data.p_brand, 'pb_name', '')} from ${d_extra} at best price and fastest delivery.`;
	const productLink = generateProductLink(data, productVariant, false);
	const productImage = getProductImage(data);

	return {
		title: title,
		description: description,
		keywords: data?.p_meta_keywords,
		alternates: {
			canonical: `${MAIN_WEB_URL}${productLink}`,
			media: {
				'only screen and (max-width: 768px)': `${SECOND_WEB_URL}${productLink}`
			}
		},
		openGraph: {
			title: title,
			description: description,
			url: productLink,
			type: 'website',
			images: [
				{
					url: typeof productImage === 'string' ? productImage : productImage?.src,
					width: 800,
					height: 800,
					alt: text
				}
			]
		}
	};
};

export const generateSchema = (data: Product, productVariant) => {
	const productTitle = generateProductTitle(data);
	const priceValidUntil = new Date();
	priceValidUntil.setDate(priceValidUntil.getDate() + 90);
	const productLink = generateProductLink(data, productVariant, false);
	const productImage = getProductImage(data);
	const schema = {
		'@context': 'http://schema.org',
		'@type': 'Product',
		name: productTitle,
		image: productImage,
		description: data.p_meta_description,
		sku: (productVariant?.pv_sku || productVariant.pv_id).toString(),

		brand: {
			'@type': 'Brand',
			name: get(data.p_brand, 'pb_name', '')
		},
		aggregateRating: {
			'@type': 'AggregateRating',
			ratingValue: '4.9',
			reviewCount: productVariant?.pv_counts?.pvc_ordered_count
				? Math.ceil(productVariant?.pv_counts?.pvc_ordered_count / 10).toString()
				: '1'
		},

		offers: {
			'@type': 'Offer',
			url: `${MAIN_WEB_URL}${productLink}`,
			priceCurrency: 'BDT',
			price: productVariant?.pv_b2c_price.toString(),
			itemCondition: 'https://schema.org/NewCondition',
			availability: 'https://schema.org/InStock',
			priceValidUntil: priceValidUntil.toISOString().split('T')[0],
			seller: {
				'@type': 'Organization',
				name: 'Arogga Ltd'
			},
			hasProductReturnPolicy: {
				'@type': 'http://schema.org/ProductReturnPolicy',
				productReturnLink: 'http://www.arogga.com/page/return-policy',
				productReturnDays: '7',
				refundType: 'FullRefund',
				inStoreReturnsOffered: 'false',
				productReturnExceptions: "You can't return the product if the seal is broken or the product is used."
			},
			hasMerchantReturnPolicy: {
				'@type': 'http://schema.org/MerchantReturnPolicy',
				merchantReturnDays: '7',
				applicableCountry: 'BD',
				returnMethod: 'http://schema.org/ReturnByMail',
				returnFees: 'https://schema.org/FreeReturn',
				returnPolicyCategory: 'https://schema.org/MerchantReturnFiniteReturnWindow',
				merchantReturnLink: 'http://www.arogga.com/page/return-policy',
				merchantReturnExceptions: "You can't return the product if the seal is broken or the product is used."
			},
			shippingDetails: {
				'@type': 'OfferShippingDetails',
				shippingDestination: {
					'@type': 'Country',
					name: 'Bangladesh'
				},
				shippingRate: {
					'@type': 'MonetaryAmount',
					value: '0',
					currency: 'BDT'
				},
				shippingLabel: 'Free Shipping',
				shippingTime: {
					'@type': 'ShippingDeliveryTime',
					shippingTimeLabel: 'Delivery in',
					shippingTime: '1-3 Business Days',
					handlingTime: '1 Business Day',
					transitTime: '1 Business Day' // Assuming handling time equals transit time for simplicity
				},
				deliveryTime: {
					'@type': 'ShippingDeliveryTime',
					shippingTimeLabel: 'Delivery in',
					shippingTime: '1-3 Business Days',
					handlingTime: {
						'@type': 'QuantitativeValue',
						value: '1',
						unitCode: 'DAY',
						maxValue: '50',
						minValue: '1'
					},
					transitTime: {
						'@type': 'QuantitativeValue',
						value: '1',
						unitCode: 'DAY',
						maxValue: '50',
						minValue: '1'
					}
				},

				insideDhaka: {
					shippingRate: {
						'@type': 'MonetaryAmount',
						value: '0',
						currency: 'BDT'
					},
					shippingTime: {
						'@type': 'ShippingDeliveryTime',
						shippingTimeLabel: 'Delivery in',
						shippingTime: '1 Business Day',
						handlingTime: '1 Business Day',
						transitTime: '1 Business Day'
					},
					deliveryTime: '1 Business Day'
				},
				outsideDhaka: {
					shippingRate: {
						'@type': 'MonetaryAmount',
						value: '0',
						currency: 'BDT'
					},
					shippingTime: {
						'@type': 'ShippingDeliveryTime',
						shippingTimeLabel: 'Delivery in',
						shippingTime: '2-3 Business Days',
						handlingTime: '1 Business Day',
						transitTime: '2 Business Days'
					},
					deliveryTime: '2-3 Business Days'
				}
			}
		},
		url: `${MAIN_WEB_URL}${productLink}`
	};
	return schema;
};

export const generateProductTitle = (data: any) => {
	const text = `${data.p_name || ''} ${data.p_strength || ''} ${data.p_form || ''}`;
	return text.replace(/(\d+)\s+\1mg/, '$1mg');
};
