'use client';
import { IC_BD, IC_COLD, IC_Dhaka_City, IC_EXPRESS, IC_REGULAR, IC_RX } from '@/assets/icons';
import { useEffect, useRef, useState } from 'react';
import { MdVerified } from 'react-icons/md';
import Tooltip from '../atoms/Tooltip/Tooltip';

interface Props {}

const PrescriptionDialog: React.FC<Props> = () => {
	const [open, setOpen] = useState(false);
	const dialogRef = useRef<HTMLDivElement>(null);

	const handleOutsideClick = (event: MouseEvent) => {
		if (dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
			setOpen(false);
		}
	};
	useEffect(() => {
		document.addEventListener('click', handleOutsideClick);
		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, []);
	return (
		<div ref={dialogRef} style={{ position: 'relative', cursor: 'pointer' }} onClick={() => setOpen(!open)}>
			<Tooltip content='This medicine requires a prescription' placement='top'>
				<IC_RX />
			</Tooltip>

			{open && (
				<div
					className='flex flex-col items-center justify-center'
					style={{
						position: 'absolute',
						top: '100%',
						right: '0',
						backgroundColor: 'white',
						zIndex: 1000,
						width: 'max-content',
						borderRadius: '10px',
						padding: '10px 10px',
						boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)'
					}}>
					<div style={{ display: 'flex', gap: '10px' }}>
						<div>
							<IC_RX />
						</div>
						<div>
							<div className='text-18 text-grey900 fw-500'>This medicine requires a prescription</div>
							<div className='mt-10'>
								<div className='text-18 mb-10'>Don’t have a prescription?</div>
								<ul>
									<li>Just add this medicine to your cart</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default PrescriptionDialog;

export const ColdDialog: React.FC<Props> = () => {
	const [open, setOpen] = useState(false);
	const dialogRef = useRef<HTMLDivElement>(null);

	const handleOutsideClick = (event: MouseEvent) => {
		if (dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
			setOpen(false);
		}
	};
	useEffect(() => {
		document.addEventListener('click', handleOutsideClick);
		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, []);
	return (
		<div ref={dialogRef} style={{ position: 'relative', cursor: 'pointer' }} onClick={() => setOpen(!open)}>
			<Tooltip content='Refrigerated' placement='top'>
				<IC_COLD />
			</Tooltip>

			{open && (
				<div
					className='flex flex-col items-center justify-center'
					style={{
						position: 'absolute',
						top: '100%',
						right: '0',
						backgroundColor: 'white',
						zIndex: 1000,
						width: 'max-content',
						borderRadius: '10px',
						padding: '10px 10px',
						boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)'
					}}>
					<div style={{ display: 'flex', gap: '10px' }}>
						<div>
							<IC_COLD />
						</div>
						<div>
							<div className='text-18 text-grey900 fw-500'>Refrigerated</div>
							<div className='mt-10'>
								<div
									className='text-14 mb-10'
									style={{
										maxWidth: '300px'
									}}>
									এই পণ্যটি পরিবহনে আরোগ্য তাপ সংবেদনশীল বক্স ব্যবহার করে
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
export const BdDialog: React.FC<Props> = () => {
	const [open, setOpen] = useState(false);
	const dialogRef = useRef<HTMLDivElement>(null);

	const handleOutsideClick = (event: MouseEvent) => {
		if (dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
			setOpen(false);
		}
	};
	useEffect(() => {
		document.addEventListener('click', handleOutsideClick);
		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, []);
	return (
		<div ref={dialogRef} style={{ position: 'relative', cursor: 'pointer' }} onClick={() => setOpen(!open)}>
			<Tooltip content='All Bangladesh' placement='top'>
				<IC_BD />
			</Tooltip>

			{open && (
				<div
					className='flex flex-col items-center justify-center'
					style={{
						position: 'absolute',
						top: '100%',
						right: '0',
						backgroundColor: 'white',
						zIndex: 1000,
						width: 'max-content',
						borderRadius: '10px',
						padding: '10px 10px',
						boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)'
					}}>
					<div style={{ display: 'flex', gap: '10px' }}>
						<div>
							<IC_BD />
						</div>
						<div>
							<div className='text-18 text-grey900 fw-500'>Bangladesh</div>
							<div className='mt-10'>
								<div
									className='text-14 mb-10'
									style={{
										maxWidth: '300px'
									}}>
									এই পণ্যটি সারা বাংলাদেশ থেকে অর্ডার করা যাবে
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
export const DhakaDialog: React.FC<Props> = () => {
	const [open, setOpen] = useState(false);
	const dialogRef = useRef<HTMLDivElement>(null);

	const handleOutsideClick = (event: MouseEvent) => {
		if (dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
			setOpen(false);
		}
	};
	useEffect(() => {
		document.addEventListener('click', handleOutsideClick);
		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, []);
	return (
		<div ref={dialogRef} style={{ position: 'relative', cursor: 'pointer' }} onClick={() => setOpen(!open)}>
			<Tooltip content='Dhaka City' placement='top'>
				<IC_Dhaka_City />
			</Tooltip>

			{open && (
				<div
					className='flex flex-col items-center justify-center'
					style={{
						position: 'absolute',
						top: '100%',
						right: '0',
						backgroundColor: 'white',
						zIndex: 1000,
						width: 'max-content',
						borderRadius: '10px',
						padding: '10px 10px',
						boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)'
					}}>
					<div style={{ display: 'flex', gap: '10px' }}>
						<div>
							<IC_Dhaka_City />
						</div>
						<div>
							<div className='text-18 text-grey900 fw-500'>Dhaka City</div>
							<div className='mt-10'>
								<div
									className='text-14 mb-10'
									style={{
										maxWidth: '300px'
									}}>
									এই পণ্যটি শুধুমাত্র ঢাকা শহরে ডেলিভারি হবে
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
export const OriginalMedicine = ({ p_type }) => {
	const [open, setOpen] = useState(false);
	const dialogRef = useRef<HTMLDivElement>(null);

	const handleOutsideClick = (event: MouseEvent) => {
		if (dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
			setOpen(false);
		}
		// if (dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
	};
	useEffect(() => {
		document.addEventListener('click', handleOutsideClick);
		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	if (p_type !== 'medicine') return null;
	return (
		<div
			ref={dialogRef}
			style={{
				position: 'relative',
				cursor: 'pointer'
			}}
			onClick={() => setOpen(!open)}>
			<span
				title={`100% Original ${p_type === 'medicine' ? 'Medicine' : 'Product'}`}
				className='text-primary d-flex justify-center items-center cursor-pointer'>
				<MdVerified size={22} />

				<Tooltip content={`100% Original ${p_type === 'medicine' ? 'Medicine' : 'Product'}`} placement='top'>
					{''}
				</Tooltip>
			</span>

			{open && (
				<div
					className='flex flex-col items-center justify-center'
					style={{
						position: 'absolute',
						top: '100%',
						right: '0',
						backgroundColor: 'white',
						zIndex: 1000,
						width: '600px',
						borderRadius: '10px',
						padding: '20px 20px',
						boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)'
					}}>
					<div style={{ display: 'flex', gap: '10px' }}>
						<div>
							<div className='text-18 text-grey900 fw-500'>আরোগ্য কিভাবে ঔষধ সংগ্রহ করে?</div>
							<div className='mt-10 relative'>
								<div
									className='text-14 mb-10'
									style={{
										maxWidth: '600px'
									}}>
									নকল এবং মানহীন ঔষধ বাংলাদেশের জন্য একটি বড় সমস্যা, তাই এই সমস্যা কাটিয়ে উঠার জন্য আমাদের
									সকল ঔষধ ক্রয় করা হয় সরাসরি কোম্পানি থেকে আরোগ্য কোন পাইকারি বিক্রেতা থেকে ঔষধ সংগ্রহ করেনা,
									সুতরাং আমাদের স্টকে থাকা ঔষধ নকল হওয়ার কোন সুযোগ নেই যেহেতু প্রতিটি ঔষধ সরাসরি
									ফার্মাসিউটিক্যাল কোম্পানি থেকেই আসছে, তাই আমাদের থেকে ক্রয়কৃত ঔষধ নিয়ে আপনি শতভাগ নিশ্চিত
									থাকতে পারেন৷ ঔষধ নকল হওয়ার সুযোগ তখনই থাকে, যখন কেউ কোম্পানি ব্যাতিত অন্য কোন উৎস থেকে ঔষধ
									সংগ্রহ করে।
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export const DeliveryDialog = ({ isRegular, className }) => {
	return (
		<div className={className}>
			<Tooltip
				style={{
					backgroundColor: 'white',
					color: 'black',
					fontSize: '12px',
					padding: '10px',
					borderRadius: '10px',
					border: '1px solid #ccc',
					boxShadow: '0px 0px 10px 0px #ccc',
					maxWidth: '200px'
				}}
				content={
					<>
						<div className='fw-700'>{isRegular ? 'Regular' : 'Express'} Delivery</div>
						<div className='mt-10'>
							<div className='text-14 mb-10'>
								{isRegular
									? '24-48 Hours delivery is available for this item.'
									: '12-24 Hours delivery is available for this item.'}
							</div>
						</div>
					</>
				}
				size='small'>
				{isRegular ? <IC_REGULAR className={className} /> : <IC_EXPRESS className={className} />}
			</Tooltip>
		</div>
	);
};
