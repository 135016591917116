export const imageLoader = ({ src, width, quality = 75 }) => {
	return src;
	// let url = `${src}?w=${width}&q=${quality || 75}`
	// const cdn = src.match(/^https:\/\/cdn(1|2)\.arogga\.com\/(.+)/);
	// if(cdn){
	//     let data = JSON.parse(decode(cdn[2]))
	//     if (data?.edits?.resize?.width) {
	//         data.edits.resize.width = width
	//         delete data.edits.resize.height
	//         url = `https://cdn${cdn[1]}.arogga.com/${encode(JSON.stringify(data))}`;
	//     }
	// }
	// else if (ASSET_CDN_URL && !/^https?:\/\//i.test(src) ) {
	//     url = `${ASSET_CDN_URL}/${src}?w=${width}&q=${quality || 75}`
	// }
	// console.log('width', width, url)
	// return src
};
